<div class="content-container">
  <div class="content-title-group not-found">
    <h2 class="title">Product Wish List</h2>
    <p>
      This project was created to help represent a fundamental app written with
      Svelte. The shopping theme is used throughout the app.
    </p>
    <br />
    <h2 class="title">Resources</h2>
    <ul>
      <li>
        <a href="https://github.com/MicrosoftDocs/mslearn-staticwebapp">
          Code in GitHub
        </a>
      </li>
    </ul>
  </div>
</div>
